import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "../layout/Admin/Footer";
import { loginUser, nextView } from "../services/authService";
import Loading from "../components/Backdrop";
import SimpleBackdrop from "../components/Backdrop";
import NextModal from "./NextModal";
import AddDefaultLocation from "../components/Admin/Location/AddDefaultLocation";
import AddDefaultUser from "../components/Admin/UserManagement/AddDefaultUser";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./style.css";
import "./login.css";
import logo from "../assets/imoneylogo.jpeg";
import { toast } from "react-toastify";
import { CancelPending, loginRequest } from "../redux/Slices/Auth";
import Coins from "../assets/Coins.json";
import Lottie from "react-lottie";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

export default function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [next, setNext] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modelAction, setModalAction] = useState("");
  const [modalContent, setModalContent] = useState({});
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const { pending, auth, sessionMsg } = useSelector((state) => state.auth);

  const session = localStorage.getItem("session");
  const token = localStorage.getItem("token");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Coins,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const showContent = (action, content) => {
    setModalAction(action);
    setModalContent(content);
    setModalShow(true);
  };

  const handleInputChange = (e) => {
    setCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNext = async () => {
    dispatch(loginRequest());
    const { data } = await nextView();
    setNext(data?.result);
    dispatch(CancelPending());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      loginUser(credentials, dispatch, history);
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    handleNext();
  }, [next?.location, next?.user]);

  useEffect(() => {
    if (token) {
      if (location.pathname == "/") {
        history.push("/check-authorization");
      } else {
        history.push(location.pathname);
      }
    }
  }, [auth?.id, token]);

  const [eyeIcon, setEyeIcon] = useState(false);
  const handleEyeIcon = () => {
    setEyeIcon(!eyeIcon);
  };

  if (sessionMsg) {
    toast.info("Your session has expired. login again to continue");
  }

  return pending ? (
    <SimpleBackdrop />
  ) : (
    <section
      className="h-100 gradient-form"
      style={{ backgroundColor: "#eee" }}
    >
      <NextModal
        action={modelAction}
        content={modalContent}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="container py-3 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              {next?.user && next?.location ? (
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <img
                          className="logo-icon me-2 pb-2"
                          src="/assets/images/iremit.png"
                          alt="logo"
                          style={{ width: "auto", height: "200px" }}
                        />
                        <h4 className="mt-1 mb-5 pb-1">
                          We are the IREMIT Team
                        </h4>
                      </div>

                      <form>
                        <p>Please login to your account</p>
                        <div className="form-outline mb-4">
                          <input
                            id="signin-email"
                            name="email"
                            type="email"
                            className="form-control signin-email"
                            placeholder="Email address"
                            required="required"
                            onChange={(e) => handleInputChange(e)}
                          />
                          <label className="form-label" htmlFor="signin-email">
                            Email
                          </label>
                        </div>

                        <div className="form-outline mb-4">
                          <div className="input-group align-items-center">
                            <input
                              id="signin-password"
                              name="password"
                              type={eyeIcon ? "text" : "password"}
                              className="form-control signin-password"
                              placeholder="Password"
                              required="required"
                              onChange={(e) => handleInputChange(e)}
                              style={{
                                borderTopRightRadius: "0",
                                borderBottomRightRadius: "0",
                              }}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                style={{
                                  padding: ".45rem",
                                  borderTopLeftRadius: "0",
                                  borderBottomLeftRadius: "0",
                                  cursor: "pointer",
                                }}
                                onClick={handleEyeIcon}
                              >
                                {eyeIcon ? (
                                  <VisibilityOffRoundedIcon />
                                ) : (
                                  <VisibilityRoundedIcon />
                                )}
                              </span>
                            </div>
                          </div>
                          <label
                            className="form-label"
                            htmlFor="signin-password"
                          >
                            Password
                          </label>

                          <div className=" mt-3 row">
                            <div className="col-12">
                              <div className="d-flex align-items-center">
                                <Link to="/reset-password">
                                  Can't access your account?
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            className=" btn btn-primary fa-lg gradient-custom-2 mb-3 w-100 theme-btn mx-auto login"
                            onClick={handleSubmit}
                          >
                            Log In
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4
                        className="mb-2"
                        style={{
                          color: "#fff",
                          fontSize: "40px",
                          textAlign: "center",
                        }}
                      >
                        We are more than just a company
                      </h4>
                      {/* <p className="small mb-0">
                       Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                       sed do eiusmod tempor incididunt ut labore et dolore magna
                       aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                       ullamco laboris nisi ut aliquip ex ea commodo consequat.
                     </p> */}
                      <Lottie
                        options={defaultOptions}
                        height={300}
                        width={300}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row g-0 p-0">
                  <div className="col-lg-6">
                    {next?.user ? (
                      <div className="card-body p-md-5 mx-md-4 text-center">
                        <div className="text-center">
                          <h4 className="mt-1 mb-5 pb-1">User Created</h4>
                        </div>
                        <div>
                          <img
                            className="logo-icon me-2 pb-2"
                            src="/assets/images/success.png"
                            alt="logo"
                            style={{ width: "150px", height: "auto" }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="card-body p-md-5 m-md-4  text-center">
                        <div className="text-center">
                          <h4 className="mt-1 mb-5 pb-1">Create User</h4>
                        </div>
                        {!next.location ? (
                          <p>
                            You must first register your location{" "}
                            <ArrowForwardIcon />{" "}
                          </p>
                        ) : (
                          <div>
                            <p>Register your first user</p>
                            <button
                              type="submit"
                              className="btn btn-success btn-md mx-1 btn-hover-shine"
                              onClick={() =>
                                showContent("Create User", <AddDefaultUser />)
                              }
                            >
                              Add User
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    {next?.location ? (
                      <div className="card-body p-md-5 m-md-4  text-center">
                        <div className="text-center">
                          <h4 className="mt-1 mb-5 pb-1">Location Created</h4>
                        </div>
                        <div>
                          <img
                            className="logo-icon me-2 pb-2"
                            src="/assets/images/success.png"
                            alt="logo"
                            style={{ width: "150px", height: "auto" }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card-body p-md-5 m-md-4  text-center"
                        style={{ backgroundColor: "#198754" }}
                      >
                        <div className="text-center">
                          <h4 className="mt-1 mb-5 pb-1">Create Location</h4>
                        </div>
                        <p>Register your first location</p>
                        {/* <AddLocation /> */}
                        <button
                          type="submit"
                          className="btn btn-success btn-md mx-1 btn-hover-shine"
                          onClick={() =>
                            showContent(
                              "Create Location",
                              <AddDefaultLocation />
                            )
                          }
                        >
                          Add Location
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
